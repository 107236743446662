import { Controller } from "@hotwired/stimulus";
import { checkFiles, attachFile, generateRandomNumber, findElement } from "../helpers"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static targets = ["fileUploadContainer", "bgUploadFile"];


  toggleInitialUploadFileBlock() {
    if (!this.hasBgUploadFileTarget || !this.fileUploadContainerTarget) return;

    if (this.fileUploadContainerTarget.querySelectorAll('.file-wrapper').length === 0) {
      this.bgUploadFileTarget.classList.remove('d-none');
    } else {
      this.bgUploadFileTarget.classList.add('d-none');
    }
  }

  handleClickFileUpload(e) {
    const fileWrapperEl = e.currentTarget.closest('.file-wrapper');
    const fileNameEditable = fileWrapperEl.querySelector(".file-name-editable");
    fileNameEditable.focus();
    const value = fileNameEditable.innerText;
    fileNameEditable.innerText = '';
    fileNameEditable.innerText = value;
    const range = document.createRange();
    range.selectNodeContents(fileNameEditable);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }


  uploadFiles(e){
    const files = e.target.files;
    const [selectedFiles] = checkFiles(files);
    const selectingFilesAmount = files.length;
    const existingFileAmount = this.fileUploadContainerTarget.querySelectorAll('.file-wrapper').length;
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/tiff',
      'application/dicom',
      'model/stl',
    ];

    if (existingFileAmount + selectingFilesAmount > 5) {
      alert("You can only upload a maximum of 5 files")
      e.target.value = '';
      return;
    }

    selectedFiles.forEach((file, index) => {
      if (!allowedFileTypes.includes(file.type)) {
        alert(`File type ${file.type} is not allowed. Only JPG, JPEG, PNG, TIF, DCM, STL are accepted.`);
        return;
      }
      const fileWrapperEl = $('<div class="file-wrapper d-flex align-items-center" data-action="click->file-upload#handleClickFileUpload"></div>')
      const fileExtName = `.${file.name.split('.').pop()}`;
      const fileBaseName = file.name.split('.').slice(0, -1).join('.');
      const $fileBaseNameEditable = $(`<div class="file-name-editable file-base-name outline-none" contentEditable="true" data-action="input->file-upload#renameFile" data-file-base-name="${fileBaseName}" data-file-ext-name="${fileExtName}">${fileBaseName}</div>`)
      const $fileName = $(`<div class="file-name font-14 w-100 d-flex min-width-0"></div>`);
      $fileName.append($fileBaseNameEditable).append(`<div class="file-ext-name">${fileExtName}</div>`);

      $(this.fileUploadContainerTarget).append(
        fileWrapperEl.append(
          $('<div class="flex-grow-1 ps-2 d-flex align-items-center h-100 min-width-0"></div>')
            .append($('<div><i class="icon-image-upload"></i></div>'))
            .append(
              $('<div class="d-flex flex-column justify-content-center flex-grow-1 min-width-0 mgl-10"></div>')
                .append($('<div class="d-flex align-items-center font-10 light-gray">File name</div>'))
                .append($fileName)
            )
        ).append(
          $(`<div class="close-wrapper pe-2 d-flex">
              <i class="icon-close-image p-2 cursor-pointer" data-action="click->file-upload#resetFileName" data-origin-name="${file.name}"></i>
              <i class="icon-trash-light p-2 cursor-pointer" data-action="click->file-upload#removeFileUpload"></i>
              </div>`)
        )
      );

      const hiddenField = document.createElement('input');
      hiddenField.setAttribute("type", "file");
      hiddenField.setAttribute("class", "d-none");
      hiddenField.name = `file_upload[${generateRandomNumber() + index}][file]`;
      hiddenField.classList.add('file-upload-input');

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      hiddenField.files = dataTransfer.files;
      fileWrapperEl.append(hiddenField);

      const limitFileSizeInMbToRunCronJob = parseInt($('#server-data').data('limit-file-size-in-mb-to-run-cronjob'));
      if(file.size > 1024*1024*limitFileSizeInMbToRunCronJob){
        hiddenField.disabled = true;
        hiddenField.classList.add('large-file-upload');
        const tooltipLargeFileEl = $('<i class="fa-solid fa-circle-exclamation fa-lg p-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Large file size"></i>');
        $fileName.parent().after(
          tooltipLargeFileEl
        );
        new Tooltip(tooltipLargeFileEl);
      }
    })
    // We will use these hidden input fields above to submit the files to the server instead of
    // the original input field, so we need to clear the original input field.
    e.target.value = '';

    this.toggleInitialUploadFileBlock();
  }

  renameFile(e){
    const fileBaseName = e.currentTarget.innerText;
    const fileExtName = e.currentTarget.dataset.fileExtName;
    const newFileName = `${fileBaseName}${fileExtName}`;

    const fileInput = e.currentTarget.closest('.file-wrapper').querySelector("input[type='file']");
    const fileUploadId = e.currentTarget.dataset.fileUploadId;
    if (fileInput) {
      // For new a file upload
      const file = fileInput.files[0];
      const newFile = new File([file], newFileName, { type: file.type })
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(newFile);
      fileInput.files = dataTransfer.files;
    } else if (fileUploadId) {
      // For edit a file upload
      const fileUploadNewFileNameInput = document.querySelector(`input.file-upload-new-file-name-${fileUploadId}`);
      const fileUploadIdInput = document.querySelector(`input.file-upload-id-${fileUploadId}`);

      if (fileUploadNewFileNameInput && fileUploadIdInput) {
        fileUploadNewFileNameInput.setAttribute('value', newFileName);
      } else {
        const randomNumber = generateRandomNumber();
        const $fileUploadNewFileNameInput = $(`<input type="text" name="file_upload[${randomNumber}][new_file_name]" value="${newFileName}" class="d-none file-upload-new-file-name-${fileUploadId}" />`);
        const $fileUploadIdInput = $(`<input type="text" name="file_upload[${randomNumber}][id]" value="${fileUploadId}" class="d-none file-upload-id-${fileUploadId}" />`);
        $(this.fileUploadContainerTarget).append($fileUploadNewFileNameInput).append($fileUploadIdInput);
      }
    }
  }

  resetFileName(e) {
    const fileNameEl = e.currentTarget.closest('.file-wrapper').querySelector('.file-name .file-base-name');
    fileNameEl.innerText = fileNameEl.dataset.fileBaseName;

    const fileInput = e.currentTarget.closest('.file-wrapper').querySelector("input[type='file']");
    const fileUploadId = e.currentTarget.dataset.fileUploadId;
    if (fileInput) {
      const originalFileName = e.currentTarget.dataset.originName;
      const file = fileInput.files[0];
      const newFile = new File([file], originalFileName, { type: file.type })
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(newFile);
      fileInput.files = dataTransfer.files;
    } else if (fileUploadId) {
      const fileUploadNewFileNameInput = document.querySelector(`input.file-upload-new-file-name-${fileUploadId}`);
      const fileUploadIdInput = document.querySelector(`input.file-upload-id-${fileUploadId}`);

      if (fileUploadNewFileNameInput && fileUploadIdInput) {
        fileUploadNewFileNameInput.remove();
        fileUploadIdInput.remove();
      }
    }
  }

  createFileUpload(fileUploadableId, fileUploadableType, file) {
    $.ajax({
      url: '/file_uploads',
      type: "POST",
      dataType: "json",
      data: {
        file_uploadable_id: fileUploadableId,
        file_uploadable_type: fileUploadableType,
        file_upload: {
          name: file.name
        }
      },
      success: (fileUpload) => {
        attachFile(fileUploadableId, fileUploadableType, fileUpload.id, file);
      },
      error: (error) => {
        console.error("Error uploading file", error)
      },
    });
  }

  removeFileUpload(event) {
    if (event.target.dataset.fileUploadId) {
      const fileUploadDestroyInput = $(`<input type="text" name="file_upload[${event.target.dataset.fileUploadId}][_destroy]" value="1" class="d-none" />`);
      const fileUploadIdInput = $(`<input type="text" name="file_upload[${event.target.dataset.fileUploadId}][id]" value="${event.target.dataset.fileUploadId}" class="d-none" />`);
      $(this.fileUploadContainerTarget).append(fileUploadDestroyInput).append(fileUploadIdInput);
    }

    $(event.target).closest('.file-wrapper').remove();

    this.toggleInitialUploadFileBlock();
  }

  attachLargeFileUpload(event) {
    const detail = event.detail;
    if (!detail || !detail.success) return;

    const largeFileUploads = [];

    this.fileUploadContainerTarget.querySelectorAll('input.large-file-upload').forEach(fileInput => {
      largeFileUploads.push(fileInput.files[0]);
      if (allowedFileTypes.includes(file.type)) {
        largeFileUploads.push(file);
      }
    });
    if (largeFileUploads.length === 0) return;

    event.detail.fetchResponse.responseHTML.then(text => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      const referralIdEl = doc.querySelector('#referral-id');

      if (referralIdEl) {
        const referralDetailsId = referralIdEl.dataset.referralId;
        largeFileUploads.forEach(file => {
          this.createFileUpload(referralDetailsId, 'Referral', file);
        });
      }
    })
  }
}
