import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "passwordInput",
    "ssoActions",
    "emailActions",
    "agreeTermsCheckBox",
    "googleSignInButton",
    "azureSignInButton",
    "auth0SignInButton",
    "termsLabel",
    "signInWithMagicLink"
  ];

  connect() {}

  agreeTermsCheckBoxTargetConnected() {
    this.agreeTermsCheckBoxTarget.addEventListener("change", () => {
      this.toggleSsoButtons();
    });
  }

  toggleSsoButtons() {
    const isAgreed = this.agreeTermsCheckBoxTarget.checked;
    const ssoButtons = document.querySelectorAll(".btn-auth");
    ssoButtons.forEach((button) => {
      button.classList.toggle("disabled", !isAgreed);
    });
  }

  ssoButtonClicked(event) {
    if (!this.agreeTermsCheckBoxTarget.checked) {
      event.preventDefault();
      const termsLabel = this.termsLabelTarget;
      termsLabel.classList.add("highlight");

      setTimeout(() => termsLabel.classList.remove("highlight"), 2000);
    }
  }

  toggleEmailAndPasswordContent() {
    $(this.emailActionsTarget).slideDown();
    $(this.ssoActionsTarget).slideUp();
    if(this.hasSignInWithMagicLinkTarget) $(this.signInWithMagicLinkTarget).slideUp();
  }

  toggleSsoContent() {
    $(this.ssoActionsTarget).slideDown();
    $(this.emailActionsTarget).slideUp();
    if(this.hasSignInWithMagicLinkTarget) $(this.signInWithMagicLinkTarget).slideUp();
  }

  toggleMagicLinkContent() {
    $(this.signInWithMagicLinkTarget).slideDown();
    $(this.emailActionsTarget).slideUp();
    $(this.ssoActionsTarget).slideUp();
  }

  passwordInputTargetConnected() {
    this.setUpEventListeners();
  }

  setUpEventListeners() {
    const passwordInput = this.passwordInputTarget;
    const form = passwordInput.closest("form");
    const requirements = form.querySelectorAll(".validate-icon");
    const validationElements = {
      uppercase: form.querySelector(".big-letter"),
      length: form.querySelector(".leng"),
      specialChar: form.querySelector(".special-character"),
      number: form.querySelector(".num"),
    };
    const passwordValidateForm = form.querySelector("#password-validate");
    const signupButton = form.querySelector("#signup-button");

    requirements.forEach((requirement) => requirement.classList.add("wrong"));

    passwordInput.addEventListener("focus", () => {
      passwordValidateForm.classList.remove("d-none");
    });

    passwordInput.addEventListener("input", () => {
      const password = passwordInput.value;

      this.validatePassword(password, validationElements, signupButton);
    });
  }

  validatePassword(password, validationElements, signupButton) {
    const passwordValidateForm = document.querySelector("#password-validate");
    const validationRules = [
      { regex: /(?=.*[A-Z])/, element: validationElements.uppercase },
      { regex: /(?=.*\d)/, element: validationElements.number },
      {
        regex: /(?=.*[!@#$%^&*()\-=+\[\]{}\\|;:'",<.>\/?`~ ])/,
        element: validationElements.specialChar,
      },
      { regex: /.{8,}/, element: validationElements.length },
    ];

    validationRules.forEach(({ regex, element }) => {
      if (regex.test(password)) {
        element.classList.remove("wrong");
        element.classList.add("good");
      } else {
        element.classList.remove("good");
        element.classList.add("wrong");
      }
    });
    const allValid = validationRules.every(({ regex }) => regex.test(password));

    if (allValid) {
      passwordValidateForm.classList.add("d-none");
      signupButton.removeAttribute("disabled");
    } else {
      passwordValidateForm.classList.remove("d-none");
      signupButton.setAttribute("disabled", "disabled");
    }
  }
}
